import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUploadAlt,  faChevronDown,  faDownload,  faSpinner,  faClock,  faCircle,  faBell,  faTimes,  faPaperPlane,} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import pdfimge from "../assets/uploa.png";
import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import down from "../assets/down.png";
import Typist from "react-typist";
import Logo from "../assets/logos.png";
import { useNavigate, useLocation } from "react-router-dom";
import "./dash.css";

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const name = searchParams.get("name");
  const mobile_no = searchParams.get("mobile_no");
  const [textInput, setTextInput] = useState("");
  const [fileInput, setFileInput] = useState(null);
  const [previewImageUrl, setPreviewImageUrl] = useState(null);
  const [messages, setMessages] = useState([]);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const [sendDisabled, setSendDisabled] = useState(true);
  const [formattedText, setFormattedText] = useState("");
  const [isNotificationMenuOpen, setIsNotificationMenuOpen] = useState(false);
  const [member, setMember] = useState(null);
  const [clubMembers, setClubMembers] = useState([]);
  const [boardMembers, setBoardMembers] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [invoices, setInvoices] = useState([]);
  const [trust, setTrust] = useState([]);
  const [tresaurer, setTresaurer] = useState([]);
  const menuRef = useRef();
  const notimenuRef = useRef();
  const chatContainerRef = useRef();

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsProfileMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutsidenoti = (event) => {
    if (notimenuRef.current && !notimenuRef.current.contains(event.target)) {
      setIsNotificationMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsidenoti);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsidenoti);
    };
  }, []);

  useEffect(() => {
    setSendDisabled(textInput.trim() === "" && !fileInput);
  }, [textInput, fileInput]);

  useEffect(() => {
    if (messages.length > 0) {
      setTextInput("");
    }
  }, [messages]);

  useEffect(() => {
    if (messages.length > 0) {
      setPreviewImageUrl("");
      scrollToBottom();
    }
  }, [messages]);

  useEffect(() => {
    if (formattedText) {
      const newMessages = [...messages];
      newMessages.push({
        sender: "bot",
        message: formattedText,
        copied: false,
      });
      setMessages(newMessages);
      setFormattedText("");
    }
  }, [formattedText, messages]);

  const scrollToBottom = () => {
    chatContainerRef.current?.scrollTo({
      top: chatContainerRef.current.scrollHeight,
      behavior: "smooth",
    });
  };

  const extractFileName = (path) => {
    return path.split("/").pop();
  };

  const handleDownload = async ({ pdfFilename, folderName }) => {
    try {
      setLoading(true);
        const s3 = new S3Client({
        region: "ap-south-1",
        credentials: {accessKeyId: "AKIAZI2LDEUZUMV2SA4F", secretAccessKey: "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",
        },
      });
      const command = new GetObjectCommand({
        Bucket: "rotary-bot",
        Key: `${folderName}/${pdfFilename}`,
      });

      const url = await getSignedUrl(s3, command, { expiresIn: 3600 });
      const response = await axios.get(url, { responseType: "blob" });
      const blob = new Blob([response.data], { type: "application/pdf" });
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", pdfFilename);
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(downloadUrl);
      setLoading(false);
    } catch (error) {
      console.error("Error downloading PDF:", error);
      setLoading(false);
    }
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    try {
      if (!textInput.trim() && !fileInput) {
        console.log("Please enter a message or upload a file.");
        return;
      }
      setLoading(true);

      const newMessages = [...messages];
      if (textInput.trim()) {
        newMessages.push({ sender: "You", message: textInput, copied: false });
      }
      if (fileInput) {
        const imageUrl = URL.createObjectURL(fileInput);
        newMessages.push({
          sender: "You",
          message: (
            <img className="imagedisplay" src={imageUrl} alt="Uploaded" />
          ),
          copied: false,
        });
      }
      setMessages(newMessages); 

      const formData = new FormData();
      formData.append("text", textInput);
      formData.append("mobile_no", mobile_no);
     
      if (fileInput) {
        formData.append("image", fileInput);
        formData.append("username", name);
      }
      const response = await fetch("https://py.rccnorth.in/Rotary_bot", {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      if (data.text) {
        setFormattedText(data.text);
      } else if (data.pdf) {
        const pdfFilePath = data.pdf;
        const folderName = pdfFilePath.split('/')[0];
        const pdfFilename = extractFileName(pdfFilePath);
        newMessages.push({
          sender: "bot",
          message: (
            <div>
              <button className="download" onClick={() => handleDownload({ pdfFilename, folderName })}  >
                <FontAwesomeIcon icon={faDownload} /> {pdfFilename}
              </button>
            </div>
          ),
          copied: false,
          share: false,
        });
      }
      setMessages(newMessages);
      setTextInput("");
      setFileInput(null);
      setPreviewImageUrl(null);
      setLoading(false);

      scrollToBottom();
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFileInput(file);

    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setPreviewImageUrl(imageUrl);
    }
  };

  const copyMessage = async (message, index) => {
    try {
      await navigator.clipboard.writeText(message);
      const newMessages = [...messages];
      newMessages[index].copied = true;
      setMessages(newMessages);

      setTimeout(() => {
        const resetMessages = [...messages];
        resetMessages[index].copied = false;
        setMessages(resetMessages);
      }, 2000);
    } catch (error) {
      console.error("Error copying message:", error);
    }
  };

  const deletePreviewImage = () => {
    setFileInput(null);
    setPreviewImageUrl(null);
  };

  const isMobileDevice = () => {
    return (
      typeof window.orientation !== "undefined" ||
      navigator.userAgent.indexOf("IEMobile") !== -1
    );
  };

  const handleKeyPress = (e) => {
    if (!isMobileDevice() && e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      sendMessage(e);
    }
  };

  const handleLogout = () => {
    setIsProfileMenuOpen(!isProfileMenuOpen);
  };

  const handleProfileLogout = () => {
    navigate("/");
  };

  const handleBellClick = () => {
    setIsNotificationMenuOpen(!isNotificationMenuOpen);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");

    const fetchMember = async () => {
      try {
        const response = await axios.get(`https://api.rccnorth.in/member/${id}`);
      if (response && response.data) {
          setMember(response.data);
          setNotificationCount(response.data.notifications?.length || 0);
        } 
      } catch (error) {
        console(error.response?.data?.error || "Something went wrong!");
      }
    };

    if (id) {
      fetchMember();
    } else {
     console.log("ID parameter not found in URL");
    }
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");
    const fetchClubMembers = async () => {
      try {
        const response = await axios.get(
          `https://api.rccnorth.in/club_members/${id}`
        );
        setClubMembers(response.data);
        setNotificationCount(response.data.length);
      } catch (error) {
        console.error("Error fetching club members:", error);
      }
    };
    fetchClubMembers();
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");
    const fetchBoardMembers = async () => {
      try {
        const response = await axios.get(`https://api.rccnorth.in/board_members/${id}`);
        setBoardMembers(response.data);
        setNotificationCount(response.data.length);
      } catch (error) {
        console.error("Error fetching board members:", error);
      }
    };

    fetchBoardMembers();
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const mobile_no = urlParams.get("mobile_no");
    const fetchninvoice = async () => {
      try {
        const response = await axios.get(`https://api.rccnorth.in/invoice/${mobile_no}`);
        console.log(response)
        setInvoices(response.data);
      } catch (error) {
        console.error("Error fetching board members:", error);
      }
    };
  fetchninvoice();
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const mobile_no = urlParams.get("mobile_no");
    const fetchTresaurer = async () => {
      try {
        const response = await axios.get(`https://api.rccnorth.in/Tresaurer/${mobile_no}`);
        setTresaurer(response.data);
      } catch (error) {
        console.error("Error fetching board members:", error);
      }
    };
    fetchTresaurer();
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const mobile_no = urlParams.get("mobile_no");
    const fetchtrust = async () => {
      try {
        const response = await axios.get(`https://api.rccnorth.in/truest/${mobile_no}`);
        console.log(response)
        setTrust(response.data);
      } catch (error) {
        console.error("Error fetching board members:", error);
      }
    };
  fetchtrust();
  }, []);




const handleDownlod = async () => {
    try {
      if (!member || !member.birthday_pdf) {
        console("Member data not available or no PDF available");
        return;
      }
      setLoading(true);
       const s3 = new S3Client({
        region: "ap-south-1",
        credentials: {
          accessKeyId: "AKIAZI2LDEUZUMV2SA4F",
          secretAccessKey: "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",
        },
      });
      const command = new GetObjectCommand({
        Bucket: "rotary-bot",
        Key: `Birthday_pdf/${member.birthday_pdf}`,
      });
     const url = await getSignedUrl(s3, command, { expiresIn: 3600 });
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `birthday_${member.name}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);  
  } catch (error) {
      console.error("Download error:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleDownlode = async () => {
    try {
      if (!member || !member.anniversary) {
        console("Member data not available or no PDF available");
        return;
      }
      setLoading(true);
       const s3 = new S3Client({
        region: "ap-south-1",
        credentials: {
          accessKeyId: "AKIAZI2LDEUZUMV2SA4F",
          secretAccessKey: "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",
        },
      });
      const command = new GetObjectCommand({
        Bucket: "rotary-bot",
        Key: `ANNIVERSARY/${member.anniversary}`,
      });
     const url = await getSignedUrl(s3, command, { expiresIn: 3600 });
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `anniversary_${member.name}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);  
    } catch (error) {
      console.error("Download error:", error);
    } finally {
      setLoading(false);
    }
  }; 
  const invoiceDownload = async (invoice_pathname) => {
    try {
      setLoading(true);
      const s3 = new S3Client({
        region: "ap-south-1",
        credentials: {
          accessKeyId: "AKIAZI2LDEUZUMV2SA4F",
          secretAccessKey: "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",
        },
      });
      const command = new GetObjectCommand({
        Bucket: "rotary-bot",
        Key: `invoice/${invoice_pathname}`,
      });
      const url = await getSignedUrl(s3, command, { expiresIn: 3600 });
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "invoice.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Download error:", error);
    } finally {
      setLoading(false);
    }
  };

  const trustDownload = async (pdf_file) => {
    try {
      setLoading(true);
      const s3 = new S3Client({
        region: "ap-south-1",
        credentials: {
          accessKeyId: "AKIAZI2LDEUZUMV2SA4F",
          secretAccessKey: "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",
        },
      });
      const command = new GetObjectCommand({
        Bucket: "rotary-bot",
        Key: `trust/${pdf_file}`,
      });
      const url = await getSignedUrl(s3, command, { expiresIn: 3600 });
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "trust.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Download error:", error);
    } finally {
      setLoading(false);
    }
  };

  const shareMessageByEmail = (message) => {
    const encodedMessage = encodeURIComponent(message);
    window.location.href = `mailto:?body=${encodedMessage}`;
  };

  return (
    <>
      <div className="chatbot-header sticky-top">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-8 col-6">
              <img src={Logo} className="logo" alt="logo" />
            </div>
            <div className="col-lg-4 col-6">
              <div className="row">
                <div className="col-4 col-lg-7 col-md-4">
                  <div className="notification-bell-container">
                    <p className="mt-3 text-end" onClick={handleBellClick}>
                      <FontAwesomeIcon
                        className="notification "
                        icon={faBell}
                      />
                      {notificationCount > 0 && (
                        <span className="notification-badge">
                          {notificationCount}
                        </span>
                      )}
                    </p>
                  </div>
                  {isNotificationMenuOpen && (
                    <div ref={notimenuRef} className="notification-menu ">
                      <div className="sticky-top">
                        <h4 className="notification-head p-2 notification-hr">
                          Notification
                        </h4>{" "}
                      </div>
                      <div className="notifioverflow">
                        <ul>
                          {member && member.birthday_pdf && (
                            <ul>
                               <li
                                className="text-dark"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <FontAwesomeIcon
                                  className="bull"
                                  icon={faCircle}
                                  style={{ marginRight: "8px" }}
                                />
                                <img
                                  src={pdfimge}
                                  className="pdfimg"
                                  alt="pdf"
                                  style={{ marginRight: "8px" }}
                                />
                                <span className="spna1" style={{ flex: 2 }}>
                                  Birthday whishes
                                </span>
                                <img
                                  onClick={handleDownlod}
                                  className="down1"
                                  src={down}
                                  alt="download"
                                />
                              </li>
                            </ul>
                          )}
                          {member && member.anniversary && (
                            <ul>
                              <li
                                className="text-dark"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <FontAwesomeIcon
                                  className="bull"
                                  icon={faCircle}
                                  style={{ marginRight: "8px" }}
                                />
                                <img
                                  src={pdfimge}
                                  className="pdfimg"
                                  alt="pdf"
                                  style={{ marginRight: "8px" }}
                                />
                                <span className="spna1" style={{ flex: 1 }}>
                                  Anniversary whishes
                                </span>
                                <img
                                  onClick={handleDownlode}
                                  className="down1"
                                  src={down}
                                  alt="download"
                                />
                              </li>
                            </ul>
                          )}{trust.map((trust) => (
                            <ul>
                           <li
                                className="text-dark"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <FontAwesomeIcon
                                  className="bull"
                                  icon={faCircle}
                                  style={{ marginRight: "8px" }}
                                />
                                <img
                                  src={pdfimge}
                                  className="pdfimg"
                                  alt="pdf"
                                  style={{ marginRight: "8px" }}
                                />
                                <span className="spna1" style={{ flex: 1 }}>
                                Trust bill <span className="text-secondary timenotification ">{trust.created_time}</span>
                                </span>
                                <img
                                 onClick={() => trustDownload(trust.pdf_file)}
                                  className="down1"
                                  src={down}
                                  alt="download"
                                />
                              </li>
                          </ul>
                          ))}   
                          {invoices.map((invoice) => (
                            <ul>
                           <li
                                className="text-dark"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <FontAwesomeIcon
                                  className="bull"
                                  icon={faCircle}
                                  style={{ marginRight: "8px" }}
                                />
                                <img
                                  src={pdfimge}
                                  className="pdfimg"
                                  alt="pdf"
                                  style={{ marginRight: "8px" }}
                                />
                                <span className="spna1 col-6" style={{ flex: 1 }}>
                                   Payment invoice<span className="text-secondary timenotification ">{invoice.created_time}</span>
                                </span>
                                <img
                                 onClick={() => invoiceDownload(invoice.invoice_pathname)}
                                  className="down1"
                                  src={down}
                                  alt="download"
                                />
                              </li>
                          </ul>
                          ))}                        
                          <div>
                          <ul>
                            {tresaurer.map((Tresaurer) => (
                              <>
                               <div className="container">
                                    <div className="row ">
                                      <div className="col-1 mt-1">
                                        <FontAwesomeIcon
                                          className="bull"
                                          icon={faCircle}
                                        />
                                      </div>
                                      <div
                                        className="col-11 mt-1 text-dark getnoti text-start"
                                       
                                      >
                                       {Tresaurer.tresaurer_notification}<span className="text-secondary timenotification ">{Tresaurer.created_time}</span>
                                      </div>
                                    </div>
                                  </div>
                              </>
                            ))}
                            </ul>
                            <ul>
                              {clubMembers.map((member) => (
                                <>
                                  <div className="container">
                                    <div className="row ">
                                      <div className="col-1">
                                        <FontAwesomeIcon
                                          className="bull"
                                          icon={faCircle}
                                        />
                                      </div>
                                      <div
                                        className="col-11 text-dark text-start getnoti"
                                        key={member.club_id}
                                      >
                                       {member.club_members}<br/><span className="text-secondary timenotification ">{member.time}</span>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ))}
                            </ul>

                            <ul>
                              {boardMembers.map((member) => (
                                <>
                                  <div className="container">
                                    <div className="row ">
                                      <div className="col-1 mt-1">
                                        <FontAwesomeIcon
                                          className="bull"
                                          icon={faCircle}
                                        />
                                      </div>
                                      <div
                                        className="col-11 mt-1 text-dark getnoti text-start"
                                        key={member.board_id}
                                      >
                                       {member.board_members}<span className="text-secondary timenotification">{member.time}</span>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ))}
                            </ul>
                           
                          </div>
                        </ul>
                      
                     </div>
                      <p className="deleteindicate text-center mt-1 mb-0">
                        <FontAwesomeIcon icon={faClock} /> After 48 hours
                        message will be Disappeared automatically.
                      </p>
                    </div>
                  )}
                </div>
                <div className=" col-8 col-lg-5 col-md-8">
                  <h5 className="name text-center mt-3" onClick={handleLogout}>
                    {name}{" "}
                    <span>
                      <FontAwesomeIcon
                        className="iconchev"
                        icon={faChevronDown}
                      />
                    </span>
                  </h5>
                  {isProfileMenuOpen && (
                    <div  ref={menuRef} className="profile-menu">
                      <ul className="profile-menu-ul">
                        <li
                          className="profile-menu-ul-li"
                          onClick={handleProfileLogout}
                        >
                          Logout
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="chat-container" ref={chatContainerRef}>
        <div className="message-containerdiv">
          <div className="messages-container">
            {messages.map((message, index) => {
              if (message.sender === "bot") {
                return (
                  <div key={index} className={`message ${message.sender} bot`}>
                    <div className="message-content">
                      {typeof message.message === "string" ? (
                        <Typist
                          avgTypingDelay={2}
                          stdTypingDelay={5}
                          cursor={{ show: false }}
                        >
                          <pre className="custom-font">{message.message}</pre>
                        </Typist>
                      ) : (
                        <Typist
                          onTypingDone={() =>
                            copyMessage(message.message, index)
                          }
                          cursor={{ show: false }}
                        >
                          {message.message}
                        </Typist>
                      )}
                      {message.copied ? (
                        <span className="material-symbols-outlined icon-copy1">
                          check
                        </span>
                      ) : (
                        <>
                          {typeof message.message === "string" && (
                            <>
                              <span
                                className="material-symbols-outlined icon-copy1"
                                onClick={() =>
                                  copyMessage(message.message, index)
                                }
                              >
                                content_copy
                              </span>
                              <span
                                className="material-symbols-outlined icon-share"
                                onClick={() =>
                                  shareMessageByEmail(message.message)
                                }
                              >
                                share
                              </span>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                );
              } else {
                return (
                  <div key={index} className={`message ${message.sender} You`}>
                    <div className="message-content">
                      {typeof message.message === "string" ? (
                        <>
                          <p className="jv">{message.message}</p>
                        </>
                      ) : (
                        <p className="jv">{message.message}</p>
                      )}
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
        </div>
        <div className="container-fluid ">
        <div className="row  ">
        <div className="d-flex justify-content-center ">
        <form onSubmit={sendMessage} className="message-form">
            {previewImageUrl && (
              <div className="image-preview">
                <img src={previewImageUrl} alt="Preview" />
                <FontAwesomeIcon
                  icon={faTimes}
                  className="delete-icon"
                  onClick={deletePreviewImage}
                />
              </div>
            )}
            <textarea
              value={textInput}
              onChange={(e) => setTextInput(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  handleKeyPress(e);
                }
              }}
              placeholder="Message Rotary....."
            ></textarea>
            <div className="file-input-container">
              <input
                type="file"
                onChange={handleFileChange}
                accept="image/*,.pdf"
                id="file-input"
              />
              <label htmlFor="file-input">
                <FontAwesomeIcon
                  className="icon-sent3"
                  icon={faCloudUploadAlt}
                />
              </label>
            </div>
            <button
              type="submit"
              className="message-button"
              disabled={sendDisabled || loading}
            >
              {loading ? (
                <FontAwesomeIcon icon={faSpinner} className="icon-sent2" spin />
              ) : (
                <FontAwesomeIcon icon={faPaperPlane} className="icon-sent1" />
              )}
            </button>
          </form>
        </div>
       </div>
        </div>
       </>
  );
};
export default Dashboard;
